export const environment = {
    production: false,
    api: {
      serverUrl: 'https://bytardag-test.etnol.it/api',
    },
    auth0: {
      domain: 'dev-6g67m8l3l7tmej54.eu.auth0.com',
      clientId: '2h5OBXAW4rTqJKEQGFNnGoCCXW2VBqKQ',
      authorizationParams: {
        audience: 'https://bytardag-test.etnol.it/api',
        redirect_uri: 'https://bytardag-test.etnol.it/callback',
      },
      errorPath: '/callback',
    },
  };
  